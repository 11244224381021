import { useState, useEffect } from 'react';
import { AdminUsersService } from '../../services/adminUsers';
import { AbilityContext } from './../../utility/context/Can'
import { Ability } from '@casl/ability'
import { connect } from "react-redux";
// import { auth } from '../../firebase';

const AbilityProvider = ({ children, auth }) => {
  const [ability, setAbility] = useState(null);

  useEffect(() => {
    if (!auth?.authId) return;
    setAbility(new Ability(auth.ability || []));
  }, [auth]);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(AbilityProvider)