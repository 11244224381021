import axios from "axios";
import { auth, urlFunctions } from "../../firebase";
import { toast } from "react-toastify";
import { sendPasswordResetEmail } from "firebase/auth";

export const AdminUsersService = {
  async getUsers(sellerId) {
    try {
      const { data } = await axios.get(`${urlFunctions}/getAdminUsers?sellerId=${sellerId}`);
      return data;
    } catch(error) {
      toast.error("Usuários não encontrados");
      console.log(error);
    }
  },

  async getUser(userId) {
    try {
      const { data } = await axios.get(`${urlFunctions}/getAdminUser?userId=${userId}`);
      return data;
    } catch(error) {
      toast.error("Usuário não encontrado");
      console.log(error);
    }
  },

  async getUserPermissions(authId) {
    try {
      const { data } = await axios.get(`${urlFunctions}/getUserPermissions?authId=${authId}`);
      return data;
    } catch(error) {
      toast.error("Usuário não encontrado.");
      console.log(error);
    }
  },

  async create({ sellerId, payload }) {
    try {
      const response = await axios.post(`${urlFunctions}/createAdminUser`, { 
        sellerId: sellerId, 
        ...payload 
      });
      if (response.status === 201) {
        await sendPasswordResetEmail(auth, payload.email);
        toast.success(`Usuário criado com sucesso! O link para criação da senha será enviado para ${payload.email}`);
      }
      return response;
    } catch(error) {
      console.log(error)
      toast.error("Erro ao criar novo usuário!")
    }
  },

  async edit({ userId, payload }) {
    try {
      const response = await axios.post(`${urlFunctions}/editAdminUser?userId=${userId}`, payload);
      if (response.status === 200) {
        toast.success("Informações do usuário alteradas com sucesso!");
        return response;
      }
    } catch(error) {
      console.log(error);
      toast.error("Erro ao alterar informações do usuário");
    }
  },

  async delete(userId) {
    try {
      const response = await axios.post(`${urlFunctions}/deleteAdminUser?userId=${userId}`);
      if (response.status === 200) {
        toast.success("Usuário deletado com sucesso!");
        return response;
      }
    } catch(error) {
      console.log(error);
      toast.error("Erro ao deletar usuário.");
    }
  }
}