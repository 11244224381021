import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const app = initializeApp({
  apiKey: "AIzaSyByo16NZ37ms25ChN4duShr4wsl5UsTjyc",
  authDomain: "catalogo-mais-odonto.firebaseapp.com",
  projectId: "catalogo-mais-odonto",
  storageBucket: "catalogo-mais-odonto.appspot.com",
  messagingSenderId: "302456946190",
  appId: "1:302456946190:web:dc0d16ead13c72a6cc865f",
  measurementId: "G-K2SQ45G3ZX"
});

let isDev = false;
try {
  isDev = window?.location?.host?.includes("localhost") ?? false;
} catch (error) {
  isDev = false;
}
const urlFunctions = isDev ? "http://localhost:5001/catalogo-mais-odonto/us-central1" : "https://us-central1-catalogo-mais-odonto.cloudfunctions.net";
const db = getFirestore(app, isDev ? "banco-dev" : "(default)");
const auth = getAuth(app);
const storage = getStorage(app, "catalogo-mais-odonto.appspot.com");

export { db, auth, storage, urlFunctions };
